import React from 'react';
import AdminResource from 'rev.sdk.js/Generators/AdminResource';
import {Button, message, Popconfirm, Tag} from 'antd';
import {useOutlet, getOutlet, getNewOutlet} from 'reconnect.js';
import * as AppActions from '../../AppActions';
import PrivateProfile from './PrivateProfile';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import constants from '../../constants';
import * as CatUtil from '../../Utils/CatUtil';
import LayaPosUserListModal from './LayaPosUserListModal';
import AdminSelectUserWidget from './AdminSelectUserWidget';
import AdminSelectProductWidget from './AdminSelectProductWidget';
import AdminSelectProductNameWidget from './AdminSelectProductNameWidget';
import AdminSelectArticleWidget from './AdminSelectArticleWidget';
import AdminDateTimeWidget from './AdminDateTimeWidget';
import AdminSelectStoreWidget from './AdminSelectStoreWidget';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import AdminStoreTopSection, {
  AdminStoreBottomSection,
} from './AdminStoreTopSection';
import AdminStoreRelatedSection from './AdminStoreRelatedSection';
import AdminUserMiddleSection from './AdminUserMiddleSection';
import qs from 'query-string';
import * as PermissionUtil from '../../Utils/PermissionUtil';
import AdminSelectCouponTemplateWidget from './AdminSelectCouponTemplateWidget';
import ArrayFieldTemplate from '../../Components/AdminArrayFieldTemplate';
import {COUPON_TEMPLATE_TYPE, COUPON_OBTAIN_METHOD} from '../../dictionary';
import * as CouponUtil from '../../Utils/CouponUtil';
import CouponCodeTopSection from './CouponCodesTopSection';
import AdminArticleBelowSection from './AdminArticleBelowSection';
import Config from '../../../data.json';

const ProductsOutlet = getOutlet('products');
const ArticlesOutlet = getOutlet('articles');

function AdminResourcePage(props) {
  useOutlet('categories');
  const {path, location, pageContext} = props;
  const [actions] = useOutlet('actions');
  const [stores] = useOutlet('stores');
  const [user] = useOutlet('user');

  function renderCustomAdminCol(props) {
    const {col, record} = props;
    if (col.customType === 'labels') {
      return record.labels?.map((l, idx) => <Tag key={idx}>{l}</Tag>);
    } else if (col.customType === 'label') {
      return record.label?.map((l, idx) => <Tag key={idx}>{l}</Tag>);
    } else if (col.customType === 'site-config-name') {
      return (
        <React.Fragment>
          <Button
            type="link"
            style={{textAlign: 'left', paddingLeft: 0}}
            onClick={() => {
              AppActions.navigate(
                `/admin/${record.name}?action=detail&id=${record.id}`,
              );
            }}>
            {constants.SITE_CONFIG[record.name]?.display || record.name}
            <div style={{color: '#bbb', fontSize: 12}}>
              {constants.SITE_CONFIG[record.name]?.value}
            </div>
          </Button>
          {record.name === 'landing' && (
            <Button
              type="link"
              onClick={() => {
                AppActions.navigate(
                  `/admin/uber_config?action=detail&id=${record.id}`,
                );
              }}
              style={{marginLeft: 10}}>
              Uber設定
            </Button>
          )}
        </React.Fragment>
      );
    } else if (col.customType === 'site-event-name') {
      return (
        <React.Fragment>
          {record.name === 'landing' ? (
            <Button
              type="link"
              onClick={() => {
                AppActions.navigate(
                  `/admin/evt_current?action=detail&id=${record.id}`,
                );
              }}>
              {record.evt_current.name}
            </Button>
          ) : (
            <Button
              type="link"
              onClick={() => {
                AppActions.navigate(
                  `/admin/${record.name}?action=detail&id=${record.id}`,
                );
              }}>
              {record.settings.name || record.name}
            </Button>
          )}
        </React.Fragment>
      );
    } else if (
      path === '/admin/store_secrets' &&
      col.customType === 'laya-store-id'
    ) {
      const store = stores.find((s) => s.id === record.store_id);
      return (
        <Button
          type="link"
          onClick={() => {
            AppActions.navigate(
              `/admin/store_secrets/?action=detail&id=${record.id}`,
            );
          }}>
          {(store && store.name) || record.store_id}
        </Button>
      );
    } else if (
      path === '/admin/store_promotions' &&
      col.customType === 'laya-store-id'
    ) {
      const store = stores.find((s) => s.id === record.store);

      return (
        <Button
          type="link"
          onClick={() => {
            AppActions.navigate(
              `/admin/store_promotions/?action=detail&id=${record.id}`,
            );
          }}>
          {(store && store.name) || record.store}
        </Button>
      );
    } else if (col.customType === 'laya-store-stock-update') {
      return (
        <Button
          disabled={!PermissionUtil.writable({path, user})}
          onClick={async () => {
            if (window.confirm(`要更新分店 [${record.name}] 的即時庫存嗎？`)) {
              AppActions.setLoading(true);
              try {
                await AppActions.adminRefreshStockCacheByStore(record.id);
                message.success('更新成功');
              } catch (ex) {
                console.warn(ex);
                alert('無法更新即時庫存\nERROR: ' + ex);
              }
              AppActions.setLoading(false);
            }
          }}>
          更新庫存
        </Button>
      );
    } else if (col.customType === 'laya-store-pos-users') {
      return (
        <Button
          disabled={!PermissionUtil.writable({path, user})}
          onClick={() => {
            getOutlet('LayaPosUserListModal').update({store: record});
          }}>
          查看詳情
        </Button>
      );
    } else if (col.customType === 'laya-pos-stock-record-store-id') {
      const store = stores.find((s) => s.id === record.store_id);
      return (store && store.name) || record.store_id;
    } else if (col.customType === 'laya-pos-stock-record-timestamp') {
      return new Date(record.created).toLocaleString('sv');
    } else if (col.customType === 'laya-user_point-owner') {
      return (
        <Button
          type="link"
          href={`/admin/user_points/?action=detail&id=${record.id}`}
          target="_blank"
          onClick={(e) => {
            e.preventDefault();
            AppActions.navigate(
              `/admin/user_points/?action=detail&id=${record.id}`,
            );
          }}>
          {`${record.name}`}
        </Button>
      );
    } else if (col.customType === 'user-point-obtain') {
      return record.obtain_meta?.event?.name;
    } else if (col.customType === 'laya-point_exchange_history-owner') {
      return record.name;
    } else if (col.customType === 'point_event_name') {
      return record.event?.name;
    } else if (col.customType === 'point_exchange_order_id') {
      return record.event?.order ? (
        <a
          href={`/admin/store_order/?id=${record.event?.order}`}
          target="_blank"
          rel="noreferrer">
          {record.event?.display_order_id}
        </a>
      ) : null;
    } else if (col.customType === 'point_exchange_coupon_id') {
      return CouponUtil.getId({id: record.event?.coupon});
    } else if (col.customType === 'point_exchange_coupon_name') {
      return CouponUtil.getId({id: record.event?.coupon_name});
    } else if (col.customType === 'laya-history-content') {
      if (typeof record.content === 'object') {
        return (
          <div>
            {Object.keys(record.content).map((key) => (
              <div key={key} style={{display: 'flex'}}>
                <div style={{flex: '0 0 100px', fontWeight: 'bold'}}>{key}</div>
                <div style={{whiteSpace: 'break-spaces'}}>
                  {typeof record.content[key] === 'object'
                    ? JSON.stringify(record.content[key], null, 2)
                    : record.content[key]}
                </div>
              </div>
            ))}
          </div>
        );
      }
      return null;
    } else if (col.customType === 'laya-flatten-field' && !!col.dataIndex) {
      const propChain = col.dataIndex.split('.');
      let value = propChain.reduce(
        (sum, p) =>
          typeof sum === 'object' && !Array.isArray(sum) && sum !== null
            ? sum[p]
            : sum,
        record,
      );
      return value;
    } else if (col.customType === 'laya-history-event-type') {
      return record.content.type
        ? record.content.type
        : record.content.evt_current
        ? 'evt_current'
        : record.content.name;
    } else if (col.customType === 'laya-history-event-name') {
      return record.collection === 'event'
        ? record.content.name
        : record.content.event_name;
    } else if (col.customType === 'laya-history-event-start') {
      return record.content.start_time
        ? new Date(record.content.start_time).toLocaleDateString('sv')
        : record.content.evt_current
        ? record.content.evt_current.start_date
        : new Date(record.content.settings.start_time).toLocaleDateString('sv');
    } else if (col.customType === 'laya-history-event-end') {
      return record.content.end_time
        ? new Date(record.content.end_time).toLocaleDateString('sv')
        : record.content.evt_current
        ? record.content.evt_current.end_date
        : new Date(record.content.settings.end_time).toLocaleDateString('sv');
    } else if (col.customType === 'laya-history-timestamp') {
      return new Date(record.created).toLocaleString('sv');
    } else if (col.customType === 'coupon-instance-obtain') {
      return (
        <React.Fragment>
          {record.obtain_meta?.obtain_method === 'CODE' ? (
            <div> {record.event_name} </div>
          ) : null}
          <div>
            {record.obtain_meta?.obtain_method === 'POINTS'
              ? '點數' + record.obtain_meta?.detail?.points + '點'
              : record.obtain_meta?.obtain_method === 'CODE'
              ? '代碼： ' + record.obtain_meta?.detail?.code
              : record.obtain_meta?.detail?.event_name ||
                COUPON_OBTAIN_METHOD.SYSTEM}
          </div>

          {record.obtain_meta?.detail?.display_order_id && (
            <a
              href={`/admin/store_order/?id=${record.obtain_meta?.detail.order_id}`}
              target="_blank"
              rel="noreferrer">
              {record.obtain_meta?.detail.display_order_id}
            </a>
          )}
        </React.Fragment>
      );
    } else if (col.customType === 'coupon-instance-detail') {
      return (
        <div>
          {record.type === 'COUPON_CASH_DISCOUNT' ? (
            <React.Fragment>
              <div>門檻金額:{record.threshold}</div>
              <div>折價金額:{record.discount_value}</div>
            </React.Fragment>
          ) : record.type === 'COUPON_TWO_FOR_ONE' ? (
            <div>買一送一餐點:{record.two_for_one_discount_product}</div>
          ) : record.type === 'COUPON_LEVEL_UP' ? (
            <React.Fragment>
              <div>升級套餐優惠價格:{record.level_up_discount_value}</div>
              <div>優惠選項群組:{record.level_up_variants_group}</div>
            </React.Fragment>
          ) : record.type === 'COUPON_EXCHANGE' ? (
            <React.Fragment>
              <div>指定餐點:{record.exchange_discount_product}</div>
              <div>指定餐點優惠價格:{record.exchange_discount_value}</div>
            </React.Fragment>
          ) : record.type === 'COUPON_DRINK_EXCHANGE' ? (
            <React.Fragment>
              <div>指定餐點:{record.drink_exchange_discount_product}</div>
              <div>指定餐點優惠價格:{record.drink_exchange_discount_value}</div>
              <div>溫度:{record.drink_specified_temperature}</div>
              <div>大小:{record.drink_specified_size}</div>
            </React.Fragment>
          ) : null}
          {record.used_meta?.used_timestamp && (
            <div>
              抵用時間:
              {new Date(record.used_meta.used_timestamp).toLocaleDateString(
                'sv',
              )}
            </div>
          )}
          {record.used_meta?.order && (
            <div>
              抵用訂單:
              <a
                href={`/admin/store_order/?id=${record.used_meta.order}`}
                target="_blank"
                rel="noreferrer">
                {record.used_meta.display_order_id}
              </a>
            </div>
          )}
          <div>顧客姓名:{record.user_name}</div>
        </div>
      );
    } else if (col.customType === 'coupon-instance-id') {
      return (
        <div>
          <div>{CouponUtil.getId(record)}</div>
          <div style={{fontSize: 10, color: '#bbb'}}>{record.id}</div>
        </div>
      );
    } else if (col.customType === 'coupon-instance-type') {
      return (record.type_display = COUPON_TEMPLATE_TYPE[record.type]);
    } else if (col.customType === 'coupon-instance-expired') {
      return (
        <div>
          <div>
            {record.start_time
              ? new Date(record.start_time).toLocaleString('sv')
              : '無起始時間'}
            {' ~ '}
          </div>
          <div>
            {record.expired_time
              ? new Date(record.expired_time).toLocaleString('sv')
              : '無使用期限'}
          </div>
        </div>
      );
    } else if (col.customType === 'coupon-instance-status') {
      return CouponUtil.status(record);
    } else if (col.customType === 'laya-store-tag') {
      return record.ENABLE_STAMP ? <Tag color="cyan">印花活動</Tag> : null;
    } else if (col.customType === 'coupon-template-type') {
      return COUPON_TEMPLATE_TYPE[record.type];
    } else if (col.customType === 'coupon-template-period') {
      return record.redeem_rules?.period_type === 'date'
        ? new Date(record.redeem_rules.period_value).toLocaleString('sv')
        : record.redeem_rules?.period_value + '天';
    } else if (col.customType === 'coupon-template-exchange-expired') {
      return (
        (record.exchange_rules?.start_time
          ? new Date(record.exchange_rules.start_time).toLocaleString('sv')
          : '無開始時間') +
        '~' +
        (record.exchange_rules?.end_time
          ? new Date(record.exchange_rules.end_time).toLocaleString('sv')
          : '無兌換期限')
      );
    } else if (col.customType === 'coupon-template-cost-points') {
      return record.exchange_rules?.cost_points;
    } else if (col.customType === 'coupon_code_owner_phones') {
      return (record.owner_phones || []).join(',');
    }
    return null;
  }

  function renderCustomAdminSection(props) {
    const {name, type, context} = props;

    if (
      type === 'form' &&
      name === 'ArticleForm' &&
      context.position === 'below-submit'
    ) {
      return <AdminArticleBelowSection {...props} path={path} />;
    } else if (
      type === 'form' &&
      name === 'ProductForm' &&
      context.position === 'top'
    ) {
      let _copyShareUrl = async () => {
        let _data = context.instance;
        let _url = actions.getReurl({
          title: encodeURIComponent(_data.name),
          image: _data.og_image || '',
          redirectUrl: `${window.location.origin}/product?id=${_data.id}`,
        });
        try {
          await navigator.clipboard.writeText(_url);
          message.success(`已複製分享連結 ${_url}`);
        } catch (err) {
          console.log(err);
          message.warn(`無法複製連結 ${_url}`);
        }
      };
      return <Button onClick={_copyShareUrl}>取得分享連結</Button>;
    } else if (name === 'UserCustom' && context.position === 'top') {
      return <PrivateProfile context={context} />;
    } else if (
      type === 'form' &&
      name === 'LayaStoreForm' &&
      context.position === 'top'
    ) {
      return <AdminStoreTopSection context={context} path={path} />;
    } else if (
      type === 'resource' &&
      path.indexOf('/admin/stores') > -1 &&
      context.position === 'middle' &&
      ['stg', 'dev'].includes(Config.stage)
    ) {
      return (
        <div>
          <Button
            style={{marginRight: 10}}
            onClick={() => {
              let store = stores.find((s) => s.name === '總部-忻旅');
              AppActions.navigate(
                '/admin/stores/?action=detail&id=' + store?.id,
              );
            }}>
            總部-忻旅
          </Button>
          <Button
            onClick={() => {
              let store = stores.find((s) => s.name === '總部-訓練教室');
              AppActions.navigate(
                '/admin/stores/?action=detail&id=' + store?.id,
              );
            }}>
            總部-訓練教室
          </Button>
        </div>
      );
    } else if (
      type === 'form' &&
      name === 'LayaStoreForm' &&
      context.position === 'bottom'
    ) {
      return <AdminStoreBottomSection context={context} path={path} />;
    } else if (
      type === 'form' &&
      name === 'LayaStoreSecretForm' &&
      context.position === 'top'
    ) {
      return <AdminStoreRelatedSection context={context} location={location} />;
    } else if (
      type === 'form' &&
      name === 'LayaStorePromotionForm' &&
      context.position === 'top'
    ) {
      return <AdminStoreRelatedSection context={context} location={location} />;
    } else if (
      type === 'form' &&
      name === 'LayaUserForm' &&
      context.position === 'top'
    ) {
      return <AdminUserMiddleSection context={context} />;
    } else if (
      type === 'resource' &&
      (path.indexOf('/admin/events') > -1 ||
        path.indexOf('/admin/legacy_event') > -1) &&
      context.position === 'top'
    ) {
      return (
        <Button
          type="primary"
          target="_blank"
          href={`/admin/history/event?query=${encodeURIComponent(
            JSON.stringify({event_name: {$exists: 1}}),
          )}`}>
          歷程紀錄
        </Button>
      );
    } else if (
      type === 'resource' &&
      path.indexOf('/admin/coupon_codes') > -1 &&
      context.position === 'top'
    ) {
      return <CouponCodeTopSection context={context} location={location} />;
    }
    return null;
  }

  if (
    path === '/admin/site' &&
    (Config.stage === 'prod' || Config.stage === 'now')
  ) {
    pageContext.resource.predefinedQueries[0].name.$nin.push(
      'pos_product_list',
    );
  } else if (path === '/admin/orders/waiting') {
    // note: use delete button to render extra actions
    pageContext.resource.renderDeleteButton = (instance, {refresh}) => {
      if (
        instance.payment_status === Cart.PAYMENT_STATUS.success ||
        instance.payment_subtype !== Cart.PAYMENT_SUBTYPE.offline
      ) {
        return null;
      }
      return (
        <Popconfirm
          title={`已經確認匯款後五碼？${
            instance.offline_tx ? '' : '注意：此筆訂單尚未填寫匯款資訊'
          }`}
          placement="topRight"
          onConfirm={async () => {
            const _hide = message.loading('更新付款狀態...');
            await AppActions.confirmOfflineOrder(instance.id);
            await refresh();
            _hide();
          }}>
          <Button type="primary">驗證</Button>
        </Popconfirm>
      );
    };
  }

  function allow1stLevelCatOnly(cat) {
    return cat.name?.split('-').length === 1;
  }

  function allow2ndLevelCatOnly(cat) {
    return cat.name?.split('-').length === 2;
  }

  if (path.indexOf('/admin/landing') > -1) {
    let cats = CatUtil.getFlattenCategories('categories');
    pageContext.resource.formSpec.schema.properties.app_cat.items.properties.subcat = {
      type: 'string',
      title: '產品分類',
      enum: cats.filter(allow2ndLevelCatOnly).map((cat) => cat.name),
      enumNames: cats.filter(allow2ndLevelCatOnly).map((cat) => cat.name),
    };

    pageContext.resource.formSpec.schema.properties.cat_menu_specials.items.properties.cat = {
      type: 'string',
      title: '產品分類',
      enum: cats.filter(allow2ndLevelCatOnly).map((cat) => cat.name),
      enumNames: cats.filter(allow2ndLevelCatOnly).map((cat) => cat.name),
    };

    pageContext.resource.formSpec.schema.properties.cat_menu_singles.items.properties.cat = {
      type: 'string',
      title: '產品分類',
      enum: cats.filter(allow2ndLevelCatOnly).map((cat) => cat.name),
      enumNames: cats.filter(allow2ndLevelCatOnly).map((cat) => cat.name),
    };
  }

  if (path.indexOf('/admin/products') > -1) {
    let cats = CatUtil.getFlattenCategories('categories');
    if (pageContext.resource.formSpec.schema?.properties?.labels?.items) {
      pageContext.resource.formSpec.schema.properties.labels.items = {
        type: 'string',
        enum: cats.filter(allow2ndLevelCatOnly).map((cat) => cat.name),
        enumNames: cats.filter(allow2ndLevelCatOnly).map((cat) => cat.name),
      };
    }

    cats = CatUtil.getFlattenCategories('promoCategories');
    if (pageContext.resource.formSpec.schema?.properties?.promotions?.items) {
      pageContext.resource.formSpec.schema.properties.promotions.items = {
        type: 'string',
        enum: cats.filter(allow2ndLevelCatOnly).map((cat) => cat.name),
        enumNames: cats.filter(allow2ndLevelCatOnly).map((cat) => cat.name),
      };
    }

    cats = getOutlet('product_attribute').getValue();
    if (pageContext.resource.formSpec.schema?.properties?.attributes?.items) {
      pageContext.resource.formSpec.schema.properties.attributes.items = {
        type: 'string',
        enum: cats.map((cat) => cat.name),
      };
    }

    cats = getOutlet('hotsearch').getValue();
    if (pageContext.resource.formSpec.schema?.properties?.hashtags?.items) {
      pageContext.resource.formSpec.schema.properties.hashtags.items = {
        type: 'string',
        enum: cats.map((cat) => cat.name),
      };
    }

    const groups = getOutlet('variantGroups').getValue();
    pageContext.resource.formSpec.schema.properties.group = {
      type: 'string',
      title: '商品選項群組',
      enum: groups.map((g) => g.name),
    };
  }

  if (path.indexOf('/admin/product_category') > -1) {
    pageContext.resource.formSpec.uiSchema.categories = {
      ...pageContext.resource.formSpec.uiSchema.categories,
      'ui:ArrayFieldTemplate': ArrayFieldTemplate,
      items: {
        ...pageContext.resource.formSpec.uiSchema.categories.items,
        items: {
          ...pageContext.resource.formSpec.uiSchema.categories.items.items,
          'ui:ArrayFieldTemplate': ArrayFieldTemplate,
        },
      },
    };
  }

  if (path.indexOf('/admin/variant_group') > -1) {
    pageContext.resource.formSpec.uiSchema.variant_groups = {
      ...pageContext.resource.formSpec.uiSchema.variant_groups,
      'ui:ArrayFieldTemplate': ArrayFieldTemplate,
      items: {
        ...pageContext.resource.formSpec.uiSchema.variant_groups.items,
        variants: {
          ...pageContext.resource.formSpec.uiSchema.variant_groups.items
            .variants,
          'ui:ArrayFieldTemplate': ArrayFieldTemplate,
          items: {
            ...pageContext.resource.formSpec.uiSchema.variant_groups.items
              .variants.items,
            choices: {
              ...pageContext.resource.formSpec.uiSchema.variant_groups.items
                .variants.items.choices,
              'ui:ArrayFieldTemplate': ArrayFieldTemplate,
            },
          },
        },
      },
    };
  }

  if (path.indexOf('/admin/meal') > -1) {
    const cats = CatUtil.getFlattenCategories('categories');
    const mealProductCats =
      pageContext.resource.formSpec.schema?.properties?.categories?.items
        ?.properties?.product_categories;
    if (mealProductCats.items) {
      mealProductCats.items = {
        ...mealProductCats.items,
        enum: cats.filter(allow2ndLevelCatOnly).map((cat) => cat.name),
        enumNames: cats.filter(allow2ndLevelCatOnly).map((cat) => cat.name),
      };
    }
  }

  if (path.indexOf('/admin/stores') > -1) {
    const cats = CatUtil.getFlattenCategories('mealCategories');
    if (pageContext.resource.formSpec.schema?.properties?.meals?.items) {
      pageContext.resource.formSpec.schema.properties.meals.items = {
        type: 'string',
        enum: cats.map((cat) => cat.name),
        enumNames: cats.map((cat) => cat.name),
      };
    }
    //hide for 督導
    delete pageContext.resource.formSpec.schema?.properties.ENABLE_STAMP;
    delete pageContext.resource.formSpec.schema?.properties.stamp_change_coupon;
    delete pageContext.resource.formSpec.schema?.properties.stamp_gift_12;
    delete pageContext.resource.formSpec.schema?.properties.stamp_gift_20;

    if (['prod', 'now'].includes(Config.stage)) {
      delete pageContext.resource.formSpec.schema?.properties
        .pos_label_announce;
    }
  }

  if (path.indexOf('/admin/store_secrets') > -1) {
    // pageContext.resource.formSpec.schema.properties.store_id = {
    //   type: 'string',
    //   title: '分店',
    //   enum: stores.map((s) => s.id),
    //   enumNames: stores.map((s) => s.name),
    // };
  }

  if (path.indexOf('/admin/store_promotions') > -1) {
    // pageContext.resource.formSpec.schema.properties.store = {
    //   type: 'string',
    //   title: '分店',
    //   enum: stores.map((s) => s.id),
    //   enumNames: stores.map((s) => s.name),
    // };
  }

  if (path.indexOf('/admin/pos_stock_records') > -1) {
    let params = qs.parse(props.location.search);
    if (params?.store_id) {
      pageContext.resource.predefinedQueries = [{store_id: params.store_id}];
    }
  }

  if (path.indexOf('/admin/user_points') > -1) {
    let params = qs.parse(props.location.search);
    if (params?.owner) {
      pageContext.resource.predefinedQueries = [{owner: params.owner}];
    }
  }

  if (path.indexOf('/admin/point_exchange_histories') > -1) {
    let params = qs.parse(props.location.search);
    if (params?.owner) {
      pageContext.resource.predefinedQueries = [{owner: params.owner}];
    }
  }

  if (path.indexOf('/admin/history') > -1) {
    let search = qs.parse(props.location.search);
    let params = {};
    let query = [];
    try {
      params = JSON.parse(decodeURIComponent(search.query));
      query.push(params);
    } catch (err) {}

    pageContext.resource.predefinedQueries = query;
  }

  if (path.indexOf('/admin/coupon_templates') > -1) {
    pageContext.resource.onCreate = () => {
      AppActions.navigate(`/admin/coupon_template?action=create`);
    };
    pageContext.resource.onGoToDetail = (record) => {
      AppActions.navigate(
        `/admin/coupon_template?action=detail&id=${record.id}`,
      );
    };
  }

  if (path.indexOf('/admin/coupon_codes') > -1) {
    let params = qs.parse(props.location.search);
    pageContext.resource.predefinedQueries = [
      {template: params?.template || '---'},
    ];
    pageContext.resource.backPath =
      '/admin/store_orders' + props.location.search;
    pageContext.resource.renderHeader = () => null;
  }

  if (path.indexOf('/admin/coupon_instances') > -1) {
    let params = qs.parse(props.location.search);
    if (params?.owner) {
      pageContext.resource.predefinedQueries = [
        {owner: params?.owner || '---'},
      ];
    } else if (params?.store_id) {
      pageContext.resource.predefinedQueries = [
        {valid_stores: {$in: ['*', params?.store_id]} || '---'},
      ];
    }
  }

  if (path.indexOf('/admin/articles') > -1) {
    const cats = CatUtil.getFlattenCategories('articleCategories');
    if (pageContext.resource.formSpec.schema?.properties?.labels?.items) {
      pageContext.resource.formSpec.schema.properties.labels.items = {
        type: 'string',
        enum: ['blog', ...cats.map((cat) => cat.name)],
        enumNames: ['blog', ...cats.map((cat) => cat.display)],
      };
    }
  }

  if (path === '/admin/events') {
    pageContext.resource.onCreate = () => {
      AppActions.navigate(`/admin/event?action=create`);
    };
    pageContext.resource.onGoToDetail = (record) => {
      AppActions.navigate(`/admin/event?action=detail&id=${record.id}`);
    };
  }

  if (path.indexOf('/admin/push_notifications') > -1) {
    pageContext.resource.renderDeleteButton = (pushRecord) => {
      return (
        <Button
          type="primary"
          onClick={async () => {
            if (!pushRecord) {
              return;
            }

            const result = window.confirm('確認要發送嗎? (此動作無法復原)');
            if (result) {
              AppActions.setLoading(true);
              try {
                await AppActions.delay(1000);
                await AppActions.sendPublicNotifications({
                  title: pushRecord.title,
                  subject: pushRecord.subject,
                  body: pushRecord.body,
                  subtitle: pushRecord.subtitle,
                  // because we won't be able to retrieve title / body from remote notif
                  // in iOS RN frontend, so we made a copy in data (userInfo)
                  data: pushRecord.data
                    ? {
                        title: pushRecord.title,
                        message: pushRecord.body,
                        ...pushRecord.data,
                      }
                    : {
                        title: pushRecord.title,
                        message: pushRecord.body,
                      },
                });
                message.success('推播已送出');
              } catch (ex) {
                console.warn(__filename, ex);
                message.error('API fail, please try again later');
              }
              AppActions.setLoading(false);
            }
          }}>
          發送
        </Button>
      );
    };
  }

  if (path.indexOf('/admin/pos_product_list') > -1) {
    let cats = CatUtil.getFlattenCategories('categories');
    let _enum = cats.filter(allow2ndLevelCatOnly).map((cat) => cat.name);
    pageContext.resource.formSpec.schema.properties.layout.items.properties.groups.items = {
      type: 'string',
      title: '商品分類 subcat',
      enum: _enum,
      enumNames: _enum,
    };
  }

  // permission control
  if (!PermissionUtil.writable({path, user})) {
    pageContext.resource.renderCreateButton = null;
    pageContext.resource.actionBar = [];
    pageContext.resource.formSpec = {
      ...pageContext.resource.formSpec,
      submitButton: null,
    };
  }

  function _modifyFetchDocumentsArgs(...args) {
    const [collection, query, ...restArgs] = args;
    let nextQuery = query;

    if (collection === 'order' && !!query.__order_type) {
      if (query.__order_type === 'normal') {
        nextQuery = {
          ...nextQuery,
          is_custom: false,
          payment_subtype: {
            $nin: [Cart.PAYMENT_SUBTYPE.offline],
          },
        };
      } else if (query.__order_type === 'offline') {
        nextQuery = {
          ...nextQuery,
          payment_subtype: Cart.PAYMENT_SUBTYPE.offline,
        };
      } else if (query.__order_type === 'custom') {
        nextQuery = {
          ...nextQuery,
          is_custom: true,
        };
      }

      delete nextQuery.__order_type;
    }

    return [collection, nextQuery, ...restArgs];
  }

  React.useEffect(() => {
    async function fetchAllProducts() {
      try {
        let resp = await JStorage.fetchAllDocuments('product', {}, null, {
          name: 1,
          price: 1,
        });
        ProductsOutlet.update(resp);
      } catch (err) {
        console.warn(err);
      }
    }

    async function fetchAllArticles() {
      try {
        let resp = await JStorage.fetchAllDocuments(
          'Article_Default',
          {},
          null,
          {
            title: 1,
          },
        );

        ArticlesOutlet.update(resp);
      } catch (err) {
        console.warn(err);
      }
    }

    fetchAllProducts();
    fetchAllArticles();
  }, []);

  const rjsfProps = {
    widgets: {
      'admin-select-user-widget': AdminSelectUserWidget,
      'admin-select-product-widget': AdminSelectProductWidget,
      'admin-select-product-name-widget': AdminSelectProductNameWidget,
      'admin-select-article-widget': AdminSelectArticleWidget,
      'admin-select-store-widget': AdminSelectStoreWidget,
      'admin-date-time-widget': AdminDateTimeWidget,
      'admin-select-coupon-template-widget': AdminSelectCouponTemplateWidget,
    },
  };

  return (
    <>
      <AdminResource
        {...props}
        rjsfProps={rjsfProps}
        modifyJStorageArgs={{
          modifyFetchDocumentsArgs: _modifyFetchDocumentsArgs,
        }}
        renderCustomAdminCol={renderCustomAdminCol}
        renderCustomAdminSection={renderCustomAdminSection}
        restructureDocuments={async (collection, jsStorageResult) => {
          if (
            collection === 'user_point' ||
            collection === 'point_exchange_history'
          ) {
            const {results} = jsStorageResult;
            const profiles = await JStorage.fetchAllDocuments('user_profile', {
              owner: {
                $in: results.map((p) => p.owner),
              },
            });

            let nextProfiles = [];
            for (let record of results) {
              const profile = profiles.find((p) => p.owner === record.owner);
              record = {
                ...record,
                name: profile?.name || '',
                phone: profile?.phone || '',
              };
              nextProfiles = [...nextProfiles, record];
            }
            jsStorageResult.results = nextProfiles;
          } else if (collection === 'coupon_instance') {
            let orders = [];
            let profiles = [];

            try {
              profiles = await JStorage.fetchAllDocuments('user_profile', {
                owner: {$in: jsStorageResult.results.map((x) => x.owner)},
              });
            } catch (err) {}

            jsStorageResult.results = jsStorageResult.results.map((c) => {
              // coupon.valid_stores = ["*", "ObjectId...", ...]
              let _stores = c.valid_stores || [];
              c.valid_stores = _stores.reduce((sum, x) => {
                if (x === '*') {
                  sum += '全部';
                } else {
                  let store = stores.find((s) => s.id === x);
                  sum += store?.name;
                }
                return sum;
              }, '');
              c.user_name = profiles.find((p) => p.owner === c.owner)?.name;
              return c;
            });
          } else if (collection === 'coupon_code') {
            let events = [];

            try {
              events = await JStorage.fetchAllDocuments('event', {
                id: {$in: jsStorageResult.results.map((x) => x.event)},
              });
            } catch (err) {}

            jsStorageResult.results = jsStorageResult.results.map((x) => {
              x.event_name =
                events.find((e) => e.id === x.event)?.name || x.event;
              return x;
            });
          }
          return jsStorageResult;
        }}
      />

      <LayaPosUserListModal />
    </>
  );
}

export default AdminResourcePage;
